/** @deprecated - this is moved to libs */
export enum CoverageIdEnum {
    // ALGEMEEN
    InShared = 'INS',
    // AUTO
    BeperktCasco = 'BC',
    Allrisk = 'CA',
    WA = 'WA',
    BeperktCascoExtra = 'BCE',
    RechtshulpOngeval = 'ORB',
    Aansprakelijkheidsverzekering = 'AVP',
    SchadeverzekeringInzittenden = 'ASVI',
    Pechhulp = 'PH',
    PechhulpEuropa = 'PHEU',
    PechhulpNederland = 'PHNL',
    OngevalBlijvendLetsel = 'INVL',
    OngevalOverlijden = 'OVRL',
    Verhaalsrechtsbijstand = 'AVHS',
    // FIETS
    Thuisbrengservice = 'FTBP',
    FietsDiefstal = 'FDIE',
    FietsAllrisk = 'FALL',
    // BROM
    BromAllrisk = 'BRCA',
    BromWA = 'BRWA',
    BromBeperktCasco = 'BRBC',
    // MOTR
    MotorAllrisk = 'MCA',
    MotorWA = 'MWA',
    MotorOpStal = 'MTOP',
    MotorSchadeOpzittenden = 'MSVO',
    MotorVerhaalsrechtsbijstand = 'MVHS',
    // WOON
    InboedelStandaard = 'INBL',
    OpstalStandaard = 'OPST',
    InboedelCompleet = 'IBAR',
    OpstalCompleet = 'OSAR',
    Glas = 'GLAS',
    MobieleElektronica = 'MBEL',
    EigenaarsHuurderbelang = 'HUAP',
    EigenaarsHuurderbelangCompleet = 'HAAR',
    Compleet = 'EXTRA',
    // CARAVAN
    CaravanAllriskNL = 'ALRN',
    CaravanAllriskEU = 'ALRE',
    CaravanInboedel = 'CINB',
    CaravanExtra = 'ACCS',
    CaravanHagelNL = 'HGLN',
    CaravanHagelEU = 'HGLE',
    // REIS
    TravelBasisEurope = 'EURB',
    TravelWorld = 'WERB',
    TravelVehicleAssistance = 'VHLP',
    TravelCancellation = 'ANNU',
    MedicalCostsEurope = 'GENE',
    MedicalCostsWorld = 'GENW',
    // DIER
    PetBasis = 'SZGP',
    PetExtra = 'FYSI',
    PetTeeth = 'TAND',
    PetChemo = 'CHEM'
}
